import _ from 'lodash'
import axios from 'axios' // jsonapi
// import { client as apollo } from './../../../apollo/client' // graphql

class HttpClient {
    static async get(endpoint, configInput = false) {
        const defaultConfig = {
            auth: {
                username: process.env.BASIC_AUTH_USERNAME,
                password: process.env.BASIC_AUTH_PASSWORD,
            }
            // headers: {
            //   'Access-Control-Allow-Origin': '*',
            // },
            // crossdomain: true
        }

        const config = configInput || defaultConfig;

        const response = await axios.get(
          `${process.env.SOFT4NET_SOURCE_BASE_URL}${endpoint}`, config);
    
          // console.log(response.data.data[0].attributes.settings.allowed_values);
    
        return response;
    }

    /**
     * @see: https://dev-api.heuresis.pl/en/jsonapi/field_storage_config/field_storage_config?filter[entity_type][value]=commerce_product&filter[field_storage_config_type][value]=list_string
     * @see: https://dev-api.heuresis.pl/en/jsonapi/field_storage_config/field_storage_config?filter[field_name][value]=field_filter_narzedzia
     * 
     * @param {*} locale pl|en
     * @param {*} resource field_storage_config/field_storage_config
     * @param {*} filter filter[entity_type][value]=commerce_product&filter[field_storage_config_type][value]=list_string
     */
    static async jsonapi(locale, resource, filter = ``) {
        if (!_.isEmpty(filter)) {
            filter = `?${filter}`;
        }

        const endpoint = `/${locale}/jsonapi/${resource}${filter}`;
        const response = await this.get(endpoint);

        return response;
    }

    static graphql() {

    }
}

export default HttpClient